.search-bar-container {
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    .search-icon {
        position: absolute;
        top: 3px;
        left: 8px;
        width: 14px;
    }
    
    .search-bar {
        border: 1px solid #6272cf;
        border-radius: 5px;
        height: 35px;
        padding: 2px;
        outline: 0;
        font-size: 12px;

    }

    input{
        width: 100%;
        text-align: center;
    }
}

