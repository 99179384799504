
.login-signup-content-container {
    display: flex;
    width: 100%;
    height: 100vh;
    padding:20px;
    justify-content: center;
    align-items: center;
    background-image: url("../../images/pyyne-florest.jfif");

    img{
        width: 40%;
        background-color: #ffffffdb;
    }

    .login-signup-form-container{
        background-color: rgba(62, 172, 68, 0.733);
        display: flex;
        width: 100%;
        max-width: 400px;
        justify-content: center;
        align-items: center;
        padding: 30px;
        border-radius: 5px;    

        .form-input-container{
            display: flex;
            margin: 3px;
            padding: 5px;

            input{
                width: 100%;
                padding: 5px;
                border-radius: 8px;
            }
        }

        .form-submit-container{
            display: flex;
            margin: 10px;
            justify-content: center;
            align-items: center;
            padding: 5px;
        

            .submit-button{
                background-color: rgb(185, 43, 43);
                display: flex;
                width: 100%;
                max-width: 166px;
                height: 40px;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: white;
                font-weight: bold;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}