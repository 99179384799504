.consultants-page-container {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;


    .page-header{
        display: flex;
        align-items: right;
        width: 93.7%;
        padding: 5px;
        justify-content: right;
        margin-bottom: 16px;
    
        button{
            padding: 10px;
            background-color: #46755D;
            color: white;
            border-radius: 3px;
            cursor: pointer;
            font-weight: bold;
        }
    }

    .page-body{
        display: flex;
        justify-content: space-between;
        align-items: top;

        .cpage-left {
            display: flex;
            flex-direction: column;
            width: 20%;
            min-width: 240px;
            padding: 20px;
            justify-content: top;
            align-items: left;
            background-color: #378f3552;

            h1{
                margin-bottom: 20px;
            }
        }
        
        .cpage-right {
            width: 80%;
        }

    }

    .page-body{
        display: flex;
        justify-content: space-between;
        align-items: top;

        @media(max-width: 930px) {
            flex-direction: column;
        }

        .cpage-left {
            display: flex;
            flex-direction: column;
            width: 20%;
            padding: 20px;
            justify-content: top;
            align-items: left;
            background-color: #378f3552;

            @media(max-width: 930px) {
                width: 100%;
                margin-bottom: 20px;
            }

            h1{
                margin-bottom: 20px;
            }
        }
        
        .cpage-right {
            width: 80%;

            @media(max-width: 930px) {
                width: 100%;
            }
        }

    }


}

