.card-container {
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: 0.5s;
    animation: ease-in;
    margin: 0px 12px 12px 20px;
    justify-content: space-between;    
    display: flex;
    width: 90%;
    padding: 20px;
    background-color: #a192271f;

    @media(max-width: 930px) {
        flex-direction: column;
        margin: 10px;
        width: 95%;
    }

    &:hover{
        transform: scale(1.05);
        box-shadow: 0px 0px 15px 0px;
    }

    .card-consultant-info-container{
        display: flex;
        flex-direction: column;
        width: 90%;
        padding: 5px;
        text-align: left;
        font-size: 13px;

        @media(max-width: 930px) {
            width: 100%;
        }

        .info-header{
            display: flex;
            width: 100%;
            justify-content: space-between;

            @media(max-width: 930px) {
                flex-direction: column-reverse;
            }

            input{
                width: 250px;
                padding: 0px;
                margin-bottom: 10px;
                border: none;
                border-color: transparent;
                cursor: pointer;

                @media(max-width: 930px) {
                    width: 100%;
                }

                &.info{
                    cursor: auto;
                }
            }
            
            .color-selection {

                .color-option{
                    width: 250px;
                    background-color: black;
                    height: 20px;
                    cursor: pointer;

                    &.green{
                        background-color: #00FF00;
                    }
                    &.yellow{
                        background-color: #FFFF00;
                    }
                    &.red{
                        background-color: #FF0000;
                    }
                    &.blue{
                        background-color: #89CFF0;
                    }

                    @media(max-width: 930px) {
                        width: 100%;
                    }
                }

                &.picker-hidden{
                    display: none;
                }

                @media(max-width: 930px) {
                    margin-bottom: 10px;
                }
            }

            .consultant-title{
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 8px;
    
                input{
                    padding: 4px;
                    font-weight: bold;
                    font-size: 20px;
                    width: 100%;
                }
            }
        }

        .consultant-info-columns{
            display: flex;

            &.other-columns{
                margin-top: 10px;
            }

            .consultant-info{
                display: flex;
                flex-direction: column;

                &.left-column{
                    width: 50%;
                    padding: 0px 5px 0px 5px;
                }

                &.right-column{
                    width: 50%;
                    padding: 0px 5px 0px 5px;
                }

                .consultant-attribute{
                    margin-top: 3px;

                    label{
                        font-weight: bold;
                        margin-right: 10px;
                    }

                    select{
                        padding: 4px;
                        border-radius: 2px;
                        width: 100%;
                    }

                    input{
                        border: 1px solid #6272cf;
                        border-radius: 5px;
                        height: 35px;
                        padding: 10px 10px 10px 10px;
                        outline: 0;
                        width: 100%;
                    }
                }
            }
        }

        .consultant-skills{
            margin-top: 4px;
            font-weight: bold;

            span{
                font-weight: normal;
                white-space: pre-line
            }
        }

        .consultant-comments{
            margin-top: 4px;
            font-weight: bold;

            span{
                font-weight: normal;
                white-space: pre-line
            }
        }

        .delete-action{
            width: 100%;
            text-align: center;
            margin-top: 15px;

            button{
                background-color: red;
                padding: 8px;
                color: white;
                border: none;
                cursor: pointer;
                font-weight: bold;
            }
        }

    }

    .card-actions-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @media(max-width: 930px) {
            align-items: center;;
        }

        button{            
            width: 50px;
            margin: 5px;
            font-weight: bold;
            font-size: 13px;
            cursor: pointer;
            background-color: #46755D;
            padding: 8px;
            text-decoration: none;
            color: white;
            border-width: 0px;

            &.save-button{
                background-color: rgb(67, 158, 55);
            }
        }
    }
}

