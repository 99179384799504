.modal-input-container{
    display: flex;
    margin: 3px;
    padding: 5px;

    input, select{
        width: 100%;
        padding: 5px;
        border-radius: 8px;
        border-width: 1px;
    }
}

.modal-bottom{
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: center;
    padding: 5px;

    button{
        background-color: green;
        display: flex;
        width: 100%;
        max-width: 166px;
        height: 40px;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: white;
        font-weight: bold;
        border-radius: 8px;
        cursor: pointer;
    }
}
