.filter-container {
    text-align: left;
    margin-top: 10px;
}

.filter-title {
    margin: 5px;
    font-size: large;
}

.filter-buttons {
    align-content: center;
    display: flex;
    justify-content: center;
}

.filter-buttons button {
    display: inline-block;
    padding: 10px;
    border-radius: 8px;
    border-style: solid;
    border-width: thin;
    color: #6272cf;
    font-weight: 700;
    cursor: pointer;
    margin: 5px;
    width: 50px;
    text-align: center;
}

.filter-buttons td {
    display: inline-block;
    padding: 2px 2px;
}
.filter-buttons button:hover {
    background-position: 100% 0%;
}

.filter-button.active {
    background-color: #6272cf;
    color: #FFF;
}