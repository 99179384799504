.navbar {
    background-color: #1c661f;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-right{
        display: flex;
        margin-right: 2%;
        align-items: center;

        .nav-pages{
            margin-right: 15px;

            @media(max-width: 590px) {
                display: none;
            }
            
            a{
                width:auto;
                padding: 10px;
                color: white;
                font-weight: bold;
                cursor: pointer;
                margin-right: 5px;
                text-decoration: none;
            }

            a:hover{
                color: black;
                font-weight: bolder;
            }
        }

        .logout-button {
            cursor: pointer;
            background-color: #f31a1a;
            display: flex;
            width: 100px;
            max-width: 100px;
            height: 40px;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: white;
            font-weight: bold;
            border-radius: 8px;
            border: none;
        }
    }

}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #578c32;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 10;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #1a83ff;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #578c32;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}



