.availability-container {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    flex-direction: column;

    input{
        border: 1px solid #6272cf;
        border-radius: 5px;
        height: 35px;
        padding: 2px 2px 2px 30px;
        outline: 0;
    }
}

