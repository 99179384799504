* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}

body {
  display: flex;
  flex-direction: column;
}

html,
body {
  min-height: 100vh;
  font-family: sans-serif;
}